<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" />
      <Steps
        :levels="level"
        v-if="level && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <van-tab title="详情页" name="a">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="框架协议信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell
                      title="框架协议名称"
                      :value="details.agreementName"
                    />
                    <van-cell title="协议编码" :value="details.agreementCode" />
                    <van-cell
                      title="签约供应商"
                      :value="details.supplierName"
                    />
                    <van-cell
                      title="签约单位"
                      :value="details.requestcompname"
                    />
                    <van-cell title="有效期" :value="details.jointprocurement">
                      <template #default>
                        <span
                          >{{ details.startTime }}至{{ details.endTime }}</span
                        >
                      </template>
                    </van-cell>
                    <van-cell title="协议类型" :value="details.typeName" />
                    <van-cell title="编制人" :value="details.username" />
                    <van-cell title="签约日期" :value="details.signedDate" />
                    <van-cell title="币种" :value="details.currencyName" />
                    <van-cell title="税率" :value="details.taxCodeDetail" />
                    <van-cell title="备注" :value="details.projectDesc" />
                  </van-cell-group>
                </van-collapse-item>
                <van-collapse-item title="申请加入信息" name="2" icon="label">
                  <van-cell-group class="group">
                    <van-cell title="申请理由" :value="details.content" />
                    <van-cell title="申请人" :value="details.createUserName" />
                    <van-cell
                      title="联系电话"
                      :value="details.applyusercontact"
                    />
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="物资信息" name="b">
            <van-list :finished="true" finished-text="没有更多了">
              <div
                class="supplies"
                v-for="(item, index) in details.stockOrderVOList"
                :key="index"
              >
                <div class="supplies-title">{{ item.productdesc }}</div>
                <div class="supplies-text">物资编码:{{ item.strprodcode }}</div>
                <div class="supplies-text">
                  数量:{{ item.quantity }}{{ item.unit }}
                </div>
                <div class="supplies-text">单价(元):{{ item.price }}</div>
              </div>
            </van-list>
          </van-tab>
          <van-tab title="ERP工厂信息" name="c"
            ><van-list :finished="true" finished-text="没有更多了">
              <div
                class="supplies"
                v-for="(item, index) in details.listERP"
                :key="index"
              >
                <div class="supplies-title">{{ item.erpFaName }}</div>
                <div class="supplies-text">
                  ERP工厂编码:{{ item.erpFaCode }}
                </div>
              </div>
            </van-list></van-tab
          >
          <van-tab title="审批记录" name="c" v-if="appStatus != 1">
            <van-list :finished="true" finished-text="没有更多了">
              <div
                class="record"
                v-for="(item, index) in details.wfapproveinfoVOList"
                :key="index"
              >
                <van-cell-group>
                  <van-cell title="审批层级" :value="item.node"></van-cell>
                  <van-cell title="审批节点" :value="item.nodename"></van-cell>
                  <van-cell title="审批人" :value="item.userName"></van-cell>
                  <van-cell
                    title="审批时间"
                    :value="item.enddatetime"
                  ></van-cell>
                  <van-cell
                    title="审批结论"
                    :value="item.resultType"
                  ></van-cell>
                  <van-cell
                    title="审批意见"
                    :value="item.result"
                    v-if="item.resultType == '不同意'"
                  ></van-cell>
                </van-cell-group>
              </div>
            </van-list>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "加入执行企业审批详情",
      level: null,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      numberrows: "2",
      projectlist: [],
      hierarchy: "",
      basicTop: {
        data: [],
        columns: [
          {
            prop: "supplierName",
            label: "签约供应商",
          },
          {
            prop: "requestcompname",
            label: "签约单位",
          },
          {
            prop: "typeName",
            label: "协议类型",
          },
          {
            prop: "agreementCode",
            label: "协议编码",
          },
          {
            prop: "startToendTime",
            label: "有效期",
          },
        ],
      },
    };
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  created() {
    this.getAddCorpToAgreementDetail();
    // this.Projectlist()
  },
  methods: {
    async getAddCorpToAgreementDetail() {
      const params = {
        addCorpToAgreeId: this.stockaffirmId,
      };
      let data = await this.$api.ec.getAddCorpToAgreementDetail(params);
      if (data) {
        this.loadingfalg = false;
      }
      data.wfnodeVOs.map((item, index) => {
        if (item.isNodeaction == 1) {
          this.level = index + 1;
          return;
        }
      });
      this.hierarchy = data.wfnodeVOs.length;
      this.details = data;
      this.basicTop.data = data;
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.push({
          name: "ecagreement",
        });
      } else {
        this.showage = true;
        this.title = "加入执行企业审批详情";
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onLoad() {
      this.Projectlist();
    },
    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          agreeJoinId: this.stockaffirmId,
          audit: 0,
          leadexam: message,
        };
      } else {
        params = {
          agreeJoinId: this.stockaffirmId,
          audit: 1,
          leadexam: "同意",
        };
      }
      let data = await this.$api.ec.auditAgreement(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "ecagreement",
            query: { type: 1 },
          });
        }, 1000);
      }
    },
    listDetails() {},
    agree() {
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.record {
  margin-top: 10px;
}
</style>
